<template>
  <div class="login">
     <div class="login__title">
       {{ emailSend ? 'На вашу почту отправлено письмо с сылкой для восстановления пароля' : 'Введите Email' }}
     </div>
    <div v-if="!emailSend" class="login__form">
      <el-form
        :model="form"
        :rules="rules"
        ref="loginForm"
      >
        <el-form-item
          label="Email"
          prop="email"
          :class="{ focused: isActiveInput === 'email' || form.email }"
        >
          <el-input
            v-model="form.email"
            @focus="isActiveInput = 'email'"
            @blur="isActiveInput = ''"
            @keydown.enter.prevent="sendEmail('loginForm')"
          />
        </el-form-item>
      </el-form>
      <div class="login__form-button">
        <ButtonElement text="Отправить" @click="sendEmail('loginForm')"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from '../components/elements/ButtonElement'
import { AlertNotify } from '@/mixin/alert_notify'

export default {
  name: 'ForgotPass',
  mixins: [AlertNotify],
  components: {
    ButtonElement,
  },
  data() {
    return {
      isActiveInput: '',
      emailSend: false,
      form: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: 'Введите Email', trigger: 'blur' },
          { type: 'email', message: 'Введите  корректный Email', trigger: ['blur', 'change'] }
        ],
      },
    }
  },
  methods: {
    sendEmail(form) {
      this.$refs[form].validate(valid => {
        if(valid) {
          const data = this.form
          this.$store.dispatch('Auth/sendEmail', data)
          .then(() => {
            this.emailSend = true
          })
          .catch(err => {
            this.errorNotify(err.response.data.errors)
          })
        }
      });
    },
  }
}
</script>

